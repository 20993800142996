import Constraints from "../constraints";
import { ResponseBase, ComprehensionView } from "./base";

export class FlightResponse extends ResponseBase {
    query: string;
    trip_type: "oneway" | "roundtrip";
    departure_airport: string[];
    destination_airport: string[];
    departure_date: string;
    num_adults: number;
    departure_time?: string;
    departure_city?: string;
    destination_city?: string;
    return_date?: string;
    return_time?: string;
    arrival_date?: string;
    arrival_time?: string;
    max_num_stops?: number;
    num_children?: number;
    cabin_class?: "economy" | "premium_economy" | "business" | "first";
    notepad?: string;

    constructor(data: Partial<FlightResponse>) {
        super();
        Object.assign(this, data);
    }

    private formatEventInfo(event: { name?: string; location?: string }, time: string): string {
        return `Event: ${event.name || 'Detected'}${event.location ? ` at ${event.location}` : ''} at ${time.split(' ')[1]}`;
    }

    private formatLegInfo(leg: any, type: 'start' | 'end'): string {
        if (leg[type]?.event) {
            return this.formatEventInfo(leg[type].event, leg[type].time);
        } else if (leg[type]) {
            const action = type === 'start' ? 'Departing' : 'Arriving';
            return `${action} ${leg[type].pivot} ${leg[type].time.split(' ')[1]}`;
        }
        return '';
    }

    render(constraints?: Constraints): ComprehensionView {
        const type = [this.trip_type === 'oneway' ? 'Oneway' : 'RoundTrip'];
        if (this.max_num_stops !== undefined) {
            type.push(this.max_num_stops === 0 ? 'Non-Stop' : this.max_num_stops === 1 ? 'Layover OK' : 'Layovers OK');
        }

        if (this.cabin_class) {
            type.push(this.cabin_class
                .replace(/_/g, ' ')
                .replace(/^\w/, c => c.toUpperCase()));
        }

        let departure = [...(this.departure_airport.length > 0 ? this.departure_airport : [this.departure_city ?? 'Unknown']), this.departure_date];
        let arrival = this.destination_airport.length > 0 ? this.destination_airport : [this.destination_city ?? 'Unknown'];
        if (this.arrival_date && this.arrival_date !== this.departure_date) {
            arrival.push(this.arrival_date);
        }
        if (this.arrival_time) {
            arrival.push(this.arrival_time);
        }
        let returnInfo: string[] = [];

        if (this.trip_type === 'roundtrip') {
            if (this.return_date) {
                returnInfo.push(this.return_date);
            }
            if (this.return_time) {
                returnInfo.push(this.return_time);
            }
        }

        if (constraints?.legs) {
            constraints.legs.forEach(leg => {
                if (leg.type === 'departure') {
                    const endInfo = this.formatLegInfo(leg, 'end');
                    if (endInfo) arrival.push(endInfo);

                    const startInfo = this.formatLegInfo(leg, 'start');
                    if (startInfo) departure.push(startInfo);
                } else if (leg.type === 'return' && this.trip_type === 'roundtrip') {
                    if (this.return_date) returnInfo.push(this.return_date);

                    const startInfo = this.formatLegInfo(leg, 'start');
                    startInfo && returnInfo.push(startInfo);

                    const endInfo = this.formatLegInfo(leg, 'end');
                    endInfo && returnInfo.push(endInfo);
                }
            });
        }

        return {
            attrs: {
                Type: type,
                Departure: departure,
                Arrival: arrival,
                Passengers: [
                    ...(this.num_adults > 1 ? [`${this.num_adults} Adults`] : [`${this.num_adults} Adult`]),
                    ...(this.num_children ? [`${this.num_children} Children`] : [])
                ],
                ...(returnInfo.length > 0 && { Return: returnInfo })
            }
        };
    }
}