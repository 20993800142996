import { makeObservable, observable, action } from "mobx";
import { toast } from "react-toastify";

import runGoogleOAuthFlow, { getGoogleConfigs } from "util/GoogleOAuth";
import { delay, exponentialBackoff } from "util/ExponentialBackoff";

import API from "util/API";

class OnboardingStore {
    public isPolling: boolean = false;
    
    public isLoading: boolean = false;
    public ingestedEmailCount: number = 0;
    public routedEmailCount: number = 0;
    public flightExtractionCount: number = 0;
    public hotelStayExtractionCount: number = 0;
    public otherCategoriesIngested: number = 0;
    public totalEmails: number = 0;
    public processedEmails: number = 0;
    public flightExtractions: number = 0;
    public hotelStayExtractions: number = 0;
    public userCreated: boolean = false;
    public onboardingCompleted: boolean = false;

    constructor() {
        makeObservable(this, {
            ingestedEmailCount: observable,
            routedEmailCount: observable,
            flightExtractions: observable,
            hotelStayExtractions: observable,
            totalEmails: observable,
            otherCategoriesIngested: observable,
            onboardingCompleted: observable,
            userCreated: observable,

            handleGoogleAuth: action,
            completeOnboarding: action,
            getOnboardingStatsSuccess: action,
            pollForStats: action,
            stopPollingForStats: action,
        });
    }

    completeOnboarding = () => {
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            API.post("/api/user/completeOnboarding", {})
                .then(data => {
                    window.location.href = '/dashboard';
                    return resolve(data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    handleGoogleAuth = (
        gmailCategoryState: string[],
        gmailTimeState: string = '',
        accountEmail: string = undefined
    ) => {
        const [allowedGmailCategories, gmailStartingAfter] = getGoogleConfigs(gmailCategoryState, gmailTimeState);

        const requestBody = {
            gmail_categories: allowedGmailCategories,
            gmail_starting_after: gmailStartingAfter,
            accountEmail,
            timeOption: gmailTimeState
        };

        runGoogleOAuthFlow(requestBody);
    };

    getOnboardingStatsSuccess = (data: any) => {
        this.isLoading = false;

        this.totalEmails = data.totalEmails;
        this.processedEmails = data.processedEmails;
        this.flightExtractions = data.flightExtractions;
        this.hotelStayExtractions = data.hotelStayExtractions;
        this.userCreated = data.userCreated;
        this.onboardingCompleted = data.onboardingCompleted;
    };

    getOnboardingStatsFailure = (error) => {
        this.isLoading = false;
    };

    getOnboardingStats = () => {
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            API.get("/api/user/onboardingStats")
                .then(response => response.json())
                .then(data => {
                    this.getOnboardingStatsSuccess(data);
                    return resolve(data);
                })
                .catch((error) => {
                    this.getOnboardingStatsFailure(error);
                    return reject(error);
                });
        });
    };

    stopPollingForStats = () => {
        this.isPolling = false;
    }

    pollForStats = async (onComplete: () => void = () => {}) => {
        this.isPolling = true;

        await exponentialBackoff(this.getOnboardingStats, 4)
            .then(({ onboardingCompleted }: any) => {
                if (onboardingCompleted) {
                    this.isPolling = false;
                } else if (this.isPolling) {
                    delay(3_000).then(() => this.pollForStats(onComplete));
                }
            })
            .catch(e => {
                console.error(e);
                toast.error('An error occurred during account onboarding.');
            });
    }
}

const onboardingStore = new OnboardingStore();

export default onboardingStore;
