import { makeObservable, action, observable } from "mobx";
import { toast } from "react-toastify";

import API from "util/API";
import { PromptSuggestion } from "components/common/Intention";
import { Job } from "models/Job";
import { ComprehensionResponse, Operation, Target } from "models/comprehension";
import { AdapterChatMessage, AdapterChatMessageTypes } from "models/Chat";

const REASON = 'Request aborted';

type AdapaterMessageProps = {
  time?: number;
  loading?: boolean;
  content?: any;
  jobId?: string;
  type: AdapterChatMessageTypes;
};

class PromptStore {
  public promptId: string;
  public messages: AdapterChatMessage[] = [];
  public promptSuggestions: PromptSuggestion[] = [];

  public loadingParseInput: boolean = false;
  public loadingSuggestions: boolean = false;
  public sendingFeedback: boolean = false;

  public comprehensionResponse: ComprehensionResponse = null;
  public rawPrompt: string = null;

  public parseRequestController: AbortController = null;

  constructor() {
    makeObservable(this, {
      messages: observable,
      promptSuggestions: observable,
      comprehensionResponse: observable,
      rawPrompt: observable,

      loadingParseInput: observable,
      loadingSuggestions: observable,
      sendingFeedback: observable,

      addComprehensionMessage: action,
      addMessageFromAdapter: action,
      addMessageFromMe: action,
      updateMessageById: action,
      removeMessageById: action,
      clearMessages: action,

      setRawPrompt: action,
      clearComprehensionResponse: action,
      setComprehensionResponseFromJob: action,

      parseInput: action,
      parseInputSuccess: action,
      parseInputFailure: action,

      getPromptSuggestions: action,
      getPromptSuggestionsSuccess: action,
      getPromptSuggestionsFailure: action,

      sendPromptFeedback: action,
      sendPromptFeedbackFailure: action,
      sendPromptFeedbackSuccess: action,
    });

    // TODO: persist a history of messages somehow?
  }

  clearMessages = () => {
    this.messages = [];
  }

  removeMessageById = (messageId: string) => {
    const index = this.messages.findIndex((m: AdapterChatMessage) => m.jobId === messageId);
    if (index >= 0) {
      this.messages.splice(index, 1);
    }
  }
  
  hasMessageId = (messageId: string) => {
    const message = this.messages.find((m: AdapterChatMessage, index: number) => m.jobId === messageId);
    return !!message;
  }

  updateMessageById = (jobId, newData) => {
    const message = this.messages.find((m: AdapterChatMessage, index: number) => m.jobId === jobId);

    if (message) {
      Object.assign(message, newData);
    }
  }

  addMessageFromMe = ({ time = Date.now(), loading = false, content = '', jobId }) => {
    this.messages.push({ name: 'You', loading, time, type: 'prompt', content, jobId: `${jobId}-prompt` });
  }

  addMessageFromAdapter = ({
    time = Date.now(),
    loading = false,
    content = '',
    type = 'agent_response',
    jobId
  }: AdapaterMessageProps) => {
    this.messages.push({ name: 'Adapter', loading, time, content, type, jobId });
  }

  addComprehensionMessage = (jobId: string) => {
    this.messages.push({ name: 'Adapter', time: Date.now(), content: this.comprehensionResponse, type: 'comprehension', jobId });
  }

  clearComprehensionResponse = () => {
    this.comprehensionResponse = null;

    if (this.parseRequestController) {
      this.parseRequestController.abort(REASON);
      this.parseRequestController = null;
    }
  };

  setComprehensionResponseFromJob = (job: Job) => {
    const { friendly_name, request_payload, trigger_event_id } = job;

    if (!request_payload) {
      this.comprehensionResponse = {
        // @ts-ignore - this is supporting legacy error handlers
        prompt_category: 'other',
      };
    } else {
      const { metadata, body } = request_payload;
      
      this.comprehensionResponse = new ComprehensionResponse({
        operation: metadata.operation[0] as Operation,
        target: metadata.operation[1] as Target,
        constraints: metadata.constraints,
        summary: body
      });
    }

    this.clearMessages();
    this.addMessageFromMe({ content: friendly_name, jobId: trigger_event_id });
    this.addComprehensionMessage(`${trigger_event_id}-comprehension`);
  };

  parseInputSuccess = (data: any) => {
    const { errors, trigger_event_id } = data;

    this.comprehensionResponse = new ComprehensionResponse(data);
    this.loadingParseInput = false;
    this.parseRequestController = null;

    const id = `${this.promptId}-comprehension`;

    // CompSvc didn't understand something, but also didn't blow up
    if (errors) {
      this.updateMessageById(id, {
        loading: false,
        type: 'unsupported',
        content: errors
      });
    }
    // memories aren't really "Jobs" so handle that response appropriately
    // @ts-ignore
    else if (data.operation && data.operation[1] === 'memory') {
      this.updateMessageById(id, {
        loading: false,
        type: 'memory',
        content: this.comprehensionResponse.render().text
      });
    } else {
      this.updateMessageById(id, {
        loading: false,
        type: 'comprehension',
        content: this.comprehensionResponse,
        jobId: `${trigger_event_id}-comprehension`
      });

      // also update the ID on the prompt
      this.updateMessageById(`${this.promptId}-prompt`, { jobId: `${trigger_event_id}-prompt` });
    }
  };

  parseInputFailure = (reason) => {
    this.loadingParseInput = false;
    this.parseRequestController = null;

    if (reason === REASON) {
      return;
    }

    this.updateMessageById(this.promptId, { loading: false, type: 'error' });
  };

  parseInput = () => {
    this.loadingParseInput = true;

    if (this.parseRequestController) {
      this.parseRequestController.abort(REASON);
    }

    this.parseRequestController = new AbortController();
    this.promptId = Date.now().toString();

    this.clearMessages();
    this.addMessageFromMe({ content: this.rawPrompt, jobId: this.promptId });
    this.addMessageFromAdapter({ loading: true, type: 'comprehension', jobId: `${this.promptId}-comprehension` });

    return new Promise((resolve, reject) => {
      API.post("/api/prompt/", { prompt: this.rawPrompt }, false, this.parseRequestController.signal)
        .then(response => response.json())
        .then(data => {
          this.parseInputSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.parseInputFailure(error);
          return error !== REASON && reject(error);
        });
    });
  };

  setRawPrompt = (prompt: string) => {
    this.rawPrompt = prompt;

    if (this.parseRequestController) {
      this.parseRequestController.abort(REASON);
      this.parseRequestController = null;
    }

    if (prompt !== '' && prompt !== null) {
      // @ts-ignore
      document.querySelector('.intention-inputbar textarea')?.focus();
    }
  }

  getPromptSuggestionsSuccess = (data: any) => {
    this.loadingSuggestions = false;
    this.promptSuggestions = data;
    return data;
  };

  getPromptSuggestionsFailure = () => {
    this.loadingSuggestions = false;
  };

  getPromptSuggestions = (jobId: string) => {
    // @ts-ignore
    this.loadingSuggestions = true;

    return new Promise((resolve, reject) => {
      API.get('/api/prompt/suggestions')
        .then(response => response.json())
        .then(data => {
          this.getPromptSuggestionsSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.getPromptSuggestionsFailure();
          return reject(error);
        });
    });
  };

  sendPromptFeedbackSuccess = (data: any) => {
    this.sendingFeedback = false;
    return data;
  };

  sendPromptFeedbackFailure = () => {
    this.sendingFeedback = false;

    toast.error(
      "An internal error occurred while submitting feedback. The error was logged, thanks for trying!",
      { theme: 'dark', position: 'bottom-right' }
    );
  };

  // response_id === uuid on ComprehensionResponse
  sendPromptFeedback = (response_id = '', feedback = '', notes = '') => {
    // @ts-ignore
    this.sendingFeedback = true;

    return new Promise((resolve, reject) => {
      API.post('/api/prompt/feedback', { response_id, feedback, notes })
        .then(response => response.json())
        .then(data => {
          this.sendPromptFeedbackSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.sendPromptFeedbackFailure();
          return reject(error);
        });
    });
  };
}

const store = new PromptStore();

export default store;