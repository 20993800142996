import React, { ChangeEvent, FormEvent } from 'react';
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";

import StepHeader from './StepHeader';
import Card from 'components/common/Card';
import BaseForm from 'components/forms/BaseForm';
import OnboardingWrapper from '../index';

type AccountDetailsProps = {
    UserStore?: any;
    OnboardingStore?: any;
};

type AccountDetailsState = {
    first_name: string | undefined;
    last_name: string | undefined;
    phone: string | undefined;
    email: string | undefined;
    homeAddress: string | undefined;
}

class AccountDetails extends React.Component<AccountDetailsProps, AccountDetailsState> {
    state = {
        first_name: undefined,
        last_name: undefined,
        phone: undefined,
        email: undefined,
        homeAddress: undefined
    };

    componentDidMount(): void {
        const { UserStore } = this.props;

        // user metadata will tell us if they need to complete onboarding
        // if that data isn't present, kick the user out
        if (!UserStore.isOnboarding) {
            window.location.href = '/';
            return;
        }

        this.setState({
            first_name: UserStore.user.first_name || '',
            last_name: UserStore.user.last_name || '',
            phone: UserStore.user.phone || '',
            email: UserStore.user.email || '',
            homeAddress: UserStore.user.metadata.homeAddress || ''
        });
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        // @ts-ignore
        this.setState({ [name]: value });
    };

    onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        const { UserStore, OnboardingStore } = this.props;
        const { email, first_name, last_name, phone, homeAddress } = this.state;

        event.preventDefault();

        UserStore.updateUser({
            // @ts-ignore
            email: email.toLowerCase(),
            first_name,
            last_name,
            phone,
            metadata: { homeAddress }
        })
            .then(OnboardingStore.completeOnboarding)
            .catch(() => {
                toast.error("Oops! Something went wrong, please try again.", { icon: false });
            });
    };

    render() {
        const { UserStore } = this.props;
        const { email, phone, first_name, last_name, homeAddress } = this.state;

        const config = [
            {
                labelText: "First Name",
                fieldId: "first_name",
                type: "text",
                value: first_name,
                required: true,
                errorText: first_name?.length === 0 ? "First name is required" : undefined
            },
            {
                labelText: "Last Name",
                fieldId: "last_name",
                type: "text",
                value: last_name,
                required: true,
                errorText: last_name?.length === 0 ? "Last name is required" : undefined
            },
            {
                labelText: "Phone",
                fieldId: "phone",
                type: "text",
                value: phone,
                required: true,
                errorText: phone?.length === 0 ? "Phone number is required" : undefined
            },
            {
                labelText: "Email Address",
                fieldId: "email",
                type: "email",
                value: email,
                disabled: true
            },
            {
                labelText: "Home Address",
                fieldId: "homeAddress",
                type: "address",
                value: homeAddress,
                required: true,
                errorText: homeAddress?.length === 0 ? "Home address is required" : undefined
            },
        ];

        return (
            <>
                <StepHeader currentStep={5} />

                <div className="adapter-two-col">

                    <div className="adapter-two-col--title">
                        <div className="flex-1"></div>

                        <h2>
                            Account Details
                        </h2>

                        <p>
                            The following details will help Adapter get to know you better.
                        </p>

                        <p>
                            Providing your phone number will allow Adapter to send you texts for your prompt results.
                        </p>

                        <div className="flex-1"></div>
                    </div>

                    <div className="adapter-two-col--content">
                        <div className="flex-1"></div>

                        <Card>
                            <BaseForm
                                config={config}
                                isLoading={UserStore.isLoading}
                                buttonText="Complete Onboarding!"
                                onChange={this.onChangeHandler}
                                onSubmit={this.onSubmitHandler}
                                style={{ minWidth: '50%' }}
                            />
                        </Card>

                        <div className="flex-1"></div>
                    </div>
                </div>
            </>
        );
    }
}

const AccountDetailsWithStores = inject("OnboardingStore", "UserStore")(observer(AccountDetails));

 const Wrapper = () => (
    <OnboardingWrapper>
        <AccountDetailsWithStores />
    </OnboardingWrapper>
 );

 export default Wrapper