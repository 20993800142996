import { makeObservable, action, observable } from "mobx";

import API from "util/API";
import { Job } from "models/Job";
import PromptStore from "./PromptStore";

class ActionLogStore {
  public currentJob: Job = undefined;
  public currentJobId: string = undefined;
  public jobs: Job[] = [];

  public loadingGetJob: boolean = false;

  constructor() {
    makeObservable(this, {
      jobs: observable,
      currentJob: observable,
      currentJobId: observable,

      loadingGetJob: observable,

      setCurrentJob: action,

      listJobs: action,
      listJobsSuccess: action,
      listJobsFailure: action,

      getJob: action,
      getJobSuccess: action,
      getJobFailure: action,
    });
  }

  listJobsSuccess = ({ jobs }) => {
    this.jobs = jobs;
  };

  listJobsFailure = () => { };

  listJobs = () => {
    return new Promise((resolve, reject) => {
      API.get("/api/prompt/jobs")
        .then(response => response.json())
        .then(data => {
          this.listJobsSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.listJobsFailure();
          return reject(error);
        });
    });
  };

  setCurrentJob = (data: any) => {
    // @ts-ignore
    this.currentJob = data;
  };

  getJobSuccess = (data: any) => {
    const { job_status, type, friendly_name, error, request_payload } = data;

    this.loadingGetJob = false;
    this.setCurrentJob(data);

    // check to see if prompt/comprehension already exists in the message queue
    // if it does not exist, be mindful of the order of the messages!
    if (!PromptStore.hasMessageId(`${this.currentJobId}-comprehension`)) {
      PromptStore.removeMessageById(this.currentJobId); // basically moving the "Loading" underneath the prompt/comprehension
      PromptStore.setComprehensionResponseFromJob(data);
      PromptStore.addMessageFromAdapter({ type: 'agent_response', loading: true, jobId: this.currentJobId });
    }

    // if job is complete, replace loading message with the agent response
    if (type === "unsupported" || (job_status !== 'pending' && job_status !== 'in_progress')) {
      PromptStore.updateMessageById(this.currentJobId, { type: 'agent_response', content: data, loading: false });
    }

    return data;
  };

  getJobFailure = () => {
    this.loadingGetJob = false;

    PromptStore.updateMessageById(this.currentJobId, { type: 'error' });
  };

  getJob = (jobId: string) => {
    // @ts-ignore
    this.loadingGetJob = true;
    this.currentJobId = jobId;

    if (!PromptStore.hasMessageId(jobId)) {
      PromptStore.addMessageFromAdapter({ type: 'agent_response', loading: true, jobId });
    }

    return new Promise((resolve, reject) => {
      API.get(`/api/prompt/jobs/${jobId}`)
        .then(response => response.json())
        .then(data => {
          this.getJobSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.getJobFailure();
          return reject(error);
        });
    });
  };
}

const store = new ActionLogStore();

export default store;