import React, { ChangeEvent } from "react";
import { inject, observer } from "mobx-react";
import { AdapterIcon } from "components/common/Icon";
import Progress from "components/common/Progress";

import './Input.scss';

export type InputWithSuggestionProps = {
    UserStore?: any;

    labelText?: string | any;
    fieldId: string;

    value?: string | number;
    placeholder?: string;
    hintText?: string;
    disabled?: boolean;
    required?: boolean;

    errorText?: string;
    error?: boolean;

    onChange: (event: ChangeEvent<any>) => void;

    style?: any;
    darkMode?: boolean;
};

class InputWithSuggestion extends React.Component<InputWithSuggestionProps, any> {
    state = {
        isLoading: false,
        suggestedText: ''
    }

    inputRef: any = null;

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    componentDidMount(): void {
        const { value } = this.props;

        if (!value || value === '') {
            this.loadSuggestion();
        }
    }

    loadSuggestion = () => {
        const { UserStore, fieldId, onChange } = this.props;

        this.setState({ isLoading: true }, () => {
            UserStore
                .getSuggestion({ key: fieldId })
                .then(({ suggestedText }: any) => {
                    this.setState({ suggestedText, isLoading: false }, () => {
                        // @ts-ignore
                        onChange({
                            target: {
                                name: fieldId,
                                value: suggestedText
                            }
                        })
                    });
                })
                .catch(() => {
                    this.setState({ isLoading: false });
                });
        });
    }

    renderTextInput = () => {
        const {
            fieldId,
            value,
            placeholder,
            errorText,
            error,
            disabled = false,
            onChange
        } = this.props;
        const { isLoading, suggestedText } = this.state;

        return (
            <div className="InputWithSuggestionButton">
                <AdapterIcon
                    ariaRole="button"
                    color={suggestedText ? '' : ''}
                    title={this.humanExplanation()}
                />

                {isLoading && (<Progress />)}

                {!isLoading && (
                    <input
                        type="text"
                        ref={this.inputRef}
                        id={fieldId}
                        className={errorText || error ? 'error' : ''}
                        name={fieldId}
                        onChange={onChange}
                        value={value ?? ''}
                        placeholder={placeholder || suggestedText}
                        disabled={disabled}
                    />
                )}
            </div>
        );
    }

    humanExplanation = () => {
        const { isLoading, suggestedText } = this.state;

        return isLoading
            ? `Adapter is using its knowledge about you to infer this value...`
            : `Adapter understands this value to be "${suggestedText}"`
    }

    render() {
        const {
            fieldId,
            hintText,
            errorText,
            labelText,
            style = {},
            darkMode = false
        } = this.props;

        const { suggestedText } = this.state;

        return (
            <div className={`adapter-form-field-input ${darkMode ? 'darkMode' : ''}`} style={style}>
                {labelText && (
                    <label htmlFor={fieldId}>
                        {labelText}
                    </label>
                )}

                {this.renderTextInput()}

                {!errorText && (hintText || suggestedText) && (
                    <p className="hintText">{hintText || this.humanExplanation()}</p>
                )}

                {errorText && (
                    <p className="errorText">{errorText}</p>
                )}
            </div>
        );
    }
}

export default inject("UserStore")(observer(InputWithSuggestion));