import Constraints from "../constraints";
import { ResponseBase, ComprehensionView } from "./base";

function convertTo12Hour(time24: string): string {
    const [hours, minutes] = time24.split(':');
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minutes} ${ampm}`;
}


export class DiningResponse extends ResponseBase {
    time_range?: [string, string];
    time?: string;
    date: string;
    search_query: string;
    locale: string;
    party_size: number;
    is_nearby?: boolean;
    nearby_landmark?: string;
    is_specific_restaurant: boolean;
    notepad?: string;

    constructor(data: Partial<DiningResponse>) {
        super();
        Object.assign(this, data);
    }

    render(constraints?: Constraints): ComprehensionView {
        const _type: string[] = [];
        _type.push(this.date);
        
        if (this.time) {
            _type.push(`At ${convertTo12Hour(this.time)}`);
        } else {
        this.time_range && _type.push(`Within ${convertTo12Hour(this.time_range[0])} - ${convertTo12Hour(this.time_range[1])}`);
        }
        this.is_specific_restaurant && _type.push(`Specific Restaurant`);

        const features: string[] = [];
        const geographicFeature = this.renderGeographicFeature(constraints);

        if (geographicFeature) {
            features.push(geographicFeature);
        } else {
            features.push(`Near ${this.locale}`);
        }

        const distanceConstraint = this.renderDistanceConstraint(constraints);
        if (distanceConstraint) {
            features.push(distanceConstraint);
        }

        return {
            attrs: {
                Type: _type,
                For: [this.party_size > 1 ? `${this.party_size} guests` : '1 guest'],
                Features: features
            }
        }
    }
}
