import React from "react";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";

import OnboardingStats from "components/stats/OnboardingStats";
import AuthenticatedPage from "components/common/AuthenticatedPage";
import ArrowContainer from "components/delight/ArrowContainer";
import Button from "components/common/Button";

import './oauth_redirect.scss';

interface ActivationProps {
  AuthStore?: any;
}

class OauthRedirect extends React.Component<ActivationProps, {}> {
  state = {
    showStats: false,
  }

  renderArrowContainer = () => {
    return (
      <ArrowContainer style={{ background: `linear-gradient(45deg, #53389E 0%, #7F56D9 100%)` }}>
        <h1 style={{ color: '#fff', fontSize: '72px' }}>Connecting your account...</h1>
      </ArrowContainer>
    );
  }

  componentDidMount() {
    const { AuthStore } = this.props;
    const searchParams = new URL(window.location.href).searchParams
    const code = searchParams.get('code');
    const error = searchParams.get('error');

    AuthStore.oauth({ code, error })
      .then(() => this.setState({ showStats: true }))
      .catch((err) => toast.error(`oAuth error.`, { icon: false }));
  }

  renderOnboardingStats = () => {
    return (
      <ArrowContainer
        className="responsive-arrow-container"
        style={{ background: `linear-gradient(45deg, #53389E 0%, #7F56D9 100%)` }}
      >
        <OnboardingStats />

        <div className="onboarding-buttons">
          <Button
            style={{ margin: '5px auto' }}
            size="small"
            disabled={false}
            onClick={() => window.location.href = "/dashboard"}
          >
            Go to dashboard
          </Button>
        </div>
      </ArrowContainer>
    );
  }

  render() {
    const { showStats } = this.state;

    return (
      <>
        {showStats ? this.renderOnboardingStats() : this.renderArrowContainer()}
      </>
    );
  }
}

const OauthRedirectWithStores = inject("AuthStore")(observer(OauthRedirect));

const Wrapper = () => (
  <AuthenticatedPage>
    <OauthRedirectWithStores />
  </AuthenticatedPage>
)

export default Wrapper;