import React from "react";
import { observer, inject } from "mobx-react";
import { toast } from "react-toastify";

type AuthenticatedPageProps = {
  children: any;
  AuthStore?: any;
  OnboardingStore?: any;
  UserStore?: any;
}

type AuthenticatedPageState = {
  isReady: boolean;
}

class AuthenticatedPage extends React.Component<AuthenticatedPageProps, AuthenticatedPageState> {
  state = {
    isReady: false
  }

  componentDidMount = () => {
    const { AuthStore, OnboardingStore, UserStore } = this.props;

    if (!AuthStore.isAuthenticated) {
      window.location.href = "/login";
      return;
    }

    // if the user should be in the onboarding flow but got lost... redirect them
    const onboardingUrlRE = /onboarding/i;
    const isOnboardingPath = onboardingUrlRE.test(window.location.pathname);

    const oAuthUrlRE = /oauth/i;
    const isOAuthPath = oAuthUrlRE.test(window.location.pathname);

    if (OnboardingStore.isOnboarding && !isOnboardingPath && !isOAuthPath) {
      window.location.href = '/onboarding';
      return;
    }

    UserStore.loadUser()
      .then(
        (user) => UserStore.loadUserAccounts()
          .then(({ connectedAccounts }) => {
            if (isOAuthPath && user.metadata?.hasCompletedOnboarding !== true ) {
              // go finish onboarding
              window.location.href = `/onboarding/ingest${window.location.search}`;
              return;
            }

            // if the user has no connected accounts, and is NOT inside onboarding, force them to onboard
            if (user.metadata?.hasCompletedOnboarding !== true && !isOnboardingPath) {
              window.location.href = '/onboarding';
              return;
            }

            this.setState({ isReady: true }, () => {
              if (user.metadata?.hasCompletedOnboarding === true && connectedAccounts?.length === 0) {
                toast.error(
                  (
                    <div>You do not have any accounts connected. <a href='/settings/accounts'>Connect account now</a>.</div>
                  ),
                  { theme: 'dark', position: 'bottom-right', icon: false }
                );
              }
              else if (connectedAccounts?.length > 0 && connectedAccounts?.some((account) => account.connected === false)) {
                toast.error(
                  (
                    <div>One of your accounts is no longer connected. <a href='/settings/accounts'>Reconnect now</a>.</div>
                  ),
                  { theme: 'dark', position: 'bottom-right', icon: false }
                );
              }
            });
          })
      );
  }

  render() {
    const { AuthStore, UserStore, children } = this.props;
    const { isReady } = this.state;

    if (!isReady) {
      return <></>;
    }

    if (!AuthStore.isAuthenticated || UserStore.user === null) {
      return <></>;
    }

    return <>{children}</>;
  }
}

export default inject('AuthStore', 'OnboardingStore', 'UserStore')(observer(AuthenticatedPage));
