import React from "react";
import { inject, observer } from "mobx-react";

import Dial from './dial/src/components/Dial';
import LogTile from './tiles/src/tiles/Log';
import ExpenseTile from "./tiles/src/tiles/ExpenseTile";
import ActivityMapTile from "./tiles/src/tiles/ActivityMap";
import MovementMapTile from "./tiles/src/tiles/Movement";

import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from 'components/common/BasePage';

import "../../styles/index.scss";
import "./index.scss";
import "./App.css";
//import "./dial/src/styles/index.css"

const TileSizes = {
    smallGraph: "small-graph",
    small: "small",
    mediumTwoColumn: "medium-two-column",
    mediumOneColumn: "medium-one-column",
    large: "large"
};

interface AtlasPageProps {
    AtlasStore?: any;
}

class AtlasPage extends React.Component<AtlasPageProps, {}> {
    render() {
        const { AtlasStore } = this.props;

        return (
            <AuthenticatedPage>
                <BasePage>
                    <div className="adapter-fullscreen-content">
                        <div className="flex-1"></div>

                        <h1 className="">Coming soon!</h1>

                        <div className="flex-1"></div>
                    </div>
                </BasePage>
            </AuthenticatedPage>
        );
    }
}

export default inject("AtlasStore")(observer(AtlasPage));
