import React from 'react';
import { inject, observer } from 'mobx-react';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'

import Card from "components/common/Card";
import Button from "components/common/Button";

import './OnboardingStats.scss';

type OnboardingStatsProps = {
    OnboardingStore?: any;
}

class OnboardingStats extends React.Component<OnboardingStatsProps, {}> {
    componentDidMount(): void {
        const { OnboardingStore } = this.props;
        OnboardingStore.pollForStats(() => {
            window.location.href = '/onboarding/details';
        });
    }
    
    render() {
        const { OnboardingStore } = this.props;

        return (
            <Card cls='responsive-card' style={{ margin: 'auto' }}>
                <h2 style={{ fontFamily: 'Rand Bold', fontSize: '22px' }}>
                    Processing Inbox...
                </h2>

                <div className="onboarding-stats">
                    <div className="onboarding-progress">
                        <CircularProgress
                            capIsRound
                            thickness='6px' 
                            size='150px' 
                            max={OnboardingStore.totalEmails} 
                            value={OnboardingStore.processedEmails} 
                            color='#7F56D9'
                        >
                            <CircularProgressLabel>
                                <h3 style={{ color: 'black', fontSize: '28px', lineHeight: '40px' }}>
                                    {OnboardingStore.processedEmails}
                                </h3>

                                <p>Out of {` ${OnboardingStore.totalEmails}`}</p>
                            </CircularProgressLabel>
                        </CircularProgress>
                    </div>

                    <div className="onboarding-text-container">
                        <div className="onboarding-text-row">
                            <p>Flight reservations ingested</p>
                            <p><span className="ingested-numbers">{OnboardingStore.flightExtractions}</span></p>
                        </div>

                        <div className="onboarding-text-row">
                            <p>Hotel reservations ingested</p>
                            <p><span className="ingested-numbers">{OnboardingStore.hotelStayExtractions}</span></p>
                        </div>

                        <div className="onboarding-text-row">
                            <p>Total emails processed</p>
                            <p><span className="total-numbers">{OnboardingStore.processedEmails}</span></p>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }
}

export default inject("OnboardingStore")(observer(OnboardingStats));