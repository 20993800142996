import React from 'react';
import { inject, observer } from 'mobx-react';

import { MartiniIcon, HotelIcon, PlaneIcon, AdapterIcon, EyeOpenIcon } from 'components/common/Icon';
import Intention, { PromptSuggestion } from './index';
import Progress from '../Progress';

const ICONS = {
    'flights': PlaneIcon,
    'hotels': HotelIcon,
    'dining': MartiniIcon,
    'memory': EyeOpenIcon,
    'other': AdapterIcon
}

type IntentionListProps = {
    NLPStore?: any;
    PromptStore?: any;
}

class IntentionList extends React.Component<IntentionListProps, {}> {
    componentDidMount(): void {
        const { PromptStore } = this.props;

        if (PromptStore.promptSuggestions.length === 0 && !PromptStore.loadingSuggestions) {
            PromptStore.getPromptSuggestions();
        }
    }

    filterSuggestions = () => {
        const { PromptStore, NLPStore } = this.props;

        return PromptStore.promptSuggestions.filter(
            suggestion => NLPStore.isCurrentTab
                ? suggestion.type === 'query'
                : suggestion.type !== 'query'
        );
    }

    render() {
        const { PromptStore, NLPStore } = this.props;

        if (PromptStore.loadingSuggestions) {
            return <Progress />;
        }

        if (PromptStore.promptSuggestions.length === 0) {
            return null;
        }

        const intentions = [];
        const suggestions = this.filterSuggestions();
        const selectedIndices = [];

        for (let i = 0, min = 0, max = suggestions.length - 1; i < 3; i++) {
            let randomIndex = Math.floor(Math.random() * (max - min + 1)) + min;

            // don't show the same option multiple times
            while (selectedIndices.includes(randomIndex)) {
                randomIndex = Math.floor(Math.random() * (max - min + 1)) + min;
            }

            const suggestion: PromptSuggestion = suggestions[randomIndex];
            selectedIndices.push(randomIndex);

            suggestion && intentions.push(
                <Intention
                    key={i}
                    title=""
                    // @ts-ignore
                    description={suggestion.short_prompt || suggestion.prompt}
                    prompt={suggestion.prompt}
                    Icon={ICONS[suggestion.category] || ICONS.other}
                    store={NLPStore.isCurrentTab ? NLPStore : PromptStore}
                />
            );
        }

        return (
            <div className="adapter-intention-list">
                {intentions}
            </div>
        );
    }
}

export default inject('NLPStore', 'PromptStore')(observer(IntentionList));