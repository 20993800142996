import React from "react";
import { inject, observer } from "mobx-react";

import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from 'components/common/BasePage';
import AdapterChat from "components/AdapterChat";
import withRouter from "hooks/withRouter";
import { Job } from "models/Job";

import "../../styles/index.scss";

interface JobByIdProps {
    ActionLogStore?: any;
    router?: any;
}

class JobByIdPage extends React.Component<JobByIdProps, {}> {
    componentDidMount(): void {
        this.pollForJobUpdate();
    }

    pollForJobUpdate = () => {
        const { ActionLogStore, router } = this.props;

        ActionLogStore.getJob(router.params.jobId)
            .then((job: Job) => {
                const { job_status, type } = job;

                if (
                    type !== 'unsupported' &&
                    (job_status === 'pending' || job_status === 'in_progress')
                ) {
                    // check for updates every 10 seconds
                    setTimeout(this.pollForJobUpdate, 10_000);
                }
            });
    }

    render() {
        return (
            <AuthenticatedPage>
                <BasePage>
                    <AdapterChat />
                </BasePage>
            </AuthenticatedPage>
        );
    }
}

export default withRouter(inject("ActionLogStore")(observer(JobByIdPage)));
