import React from 'react';
import L from "leaflet";

import Button from 'components/common/Button';
import Pill from 'components/common/Pill';

import './RestaurantOption.scss';

const MAP_LOCATION_COLOR = '#EF6820';

function convertTo12Hour(time24: string): string {
    const [hours, minutes] = time24.split(':');
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minutes} ${ampm}`;
}

type RestaurantResult = {
    name: string;
    reserve_url: string;
    time: string;
    address: string;
    reviews?: number;
    reserve_source: string;
    price?: string;
    rating?: number;
    type?: string;
    types?: string[];
    order_online_url?: string;
    website?: string;
    gps_coordinates?: {
        lat: number;
        lng: number;
    }
}

type RestaurantOptionProps = {
    option: RestaurantResult;
    index: number;
}

export class RestaurantOption extends React.Component<RestaurantOptionProps, { map: L.Map | null }> {
    private mapRef = React.createRef<HTMLDivElement>();

    state = {
        map: null as L.Map | null
    }

    componentDidMount(): void {
        this.drawMap();
    }

    drawMap = () => {
        const { option } = this.props;
        const lat = option.gps_coordinates?.lat;
        const lng = option.gps_coordinates?.lng;

        if (!this.state.map && lat && lng && this.mapRef.current) {
            let zoomScale = 16;

            const initializedMap = L.map(this.mapRef.current, {
                zoomControl: false,
                attributionControl: false,
            }).setView([lat, lng], zoomScale);

            const tileLayer = L.tileLayer(
                "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ).addTo(initializedMap);

            tileLayer.getContainer().style.filter = "grayscale(100%)";

            L.svg({ clickable: true }).addTo(initializedMap);

            L.circleMarker([lat, lng], {
                color: MAP_LOCATION_COLOR,
                fillColor: MAP_LOCATION_COLOR,
                fillOpacity: 0.5,
                radius: 4
            }).addTo(initializedMap);

            this.setState({ map: initializedMap });
        }
    };

    render() {
        const { option, index } = this.props;
        const isTopPick = index === 0;
        const lat = option.gps_coordinates?.lat;
        const lng = option.gps_coordinates?.lng;
        const types = [];

        const allTypes = [option.type, ...(option.types || [])].filter(Boolean).map(type => type.replace('restaurant', '').trim());
        types.push(...new Set(allTypes));

        return (
            <div className="adapter-intention-option">
                <div className="option-price-header">
                    <div className="option-price-header-wrapper">
                        <h3>{option.name}</h3>
                        {option.website && (
                            <p>
                                <a href={option.website} target="_blank" rel="noopener noreferrer">
                                    Visit Website
                                </a>
                            </p>
                        )}
                    </div>
                    {isTopPick && <Pill highlighted>Best Match</Pill>}
                </div>

                <div className="option-details">
                    {option.time && (
                        <div className="option-time">
                            Time: {convertTo12Hour(option.time)}
                        </div>
                    )}

                    {types.length > 0 && (
                        <div className="option-types">
                            {types.join(', ')}
                        </div>
                    )}

                    {option.address && <p>{option.address}</p>}

                    {lat && lng && (
                        <div className="option-details-body-wrapper">
                            <div ref={this.mapRef} style={{ height: '300px', flex: 1 }}></div>
                        </div>
                    )}
                </div>
                <div className="option-details-small">
                    {option.price && <span>Price: {option.price}</span>}
                    {option.rating && <span>Rating: {option.rating}</span>}
                    {option.reviews !== undefined && <span># Reviews: {option.reviews}</span>}
                </div>

                {option.reserve_url && (
                    <Button
                        size="2xl"
                        onClick={() => window.open(option.reserve_url, '_blank')}
                        style={{ width: '100%', marginTop: '12px' }}
                    >Book Now</Button>
                )}
            </div>
        );
    }
}

export default RestaurantOption;