import React from "react";
import { inject, observer } from 'mobx-react';

import AuthenticatedPage from "components/common/AuthenticatedPage";
import GettingStarted from "./GettingStarted";

import "../../styles/index.scss";

type DashboardProps = {
    OnboardingStore?: any;
}

const DashboardPage = ({ OnboardingStore }: DashboardProps) => {
    return (
        <AuthenticatedPage>
            <GettingStarted />
        </AuthenticatedPage>
    );
};

export default inject("OnboardingStore")(observer(DashboardPage));